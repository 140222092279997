.main-wrapper1 {
    height: 100%;
}

.ulp-outer1 {
    display: flex;
    align-items: center;
    overflow-y: auto;
    width: 99vw;
    height: 100%;
    padding: 80px 0;
    margin: auto;
}

.ulp-box1 {
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 450px;
    width: 100%;
    height: auto;
    margin: auto;
    white-space: normal;
    border-radius: 5px;
}

.closeIcon1 {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
}

.ulp-header1 #prompt-logo-center {
    vertical-align: top;
    height: 60px;
}

.michelin-logo {
    text-align: center;
}

.header-logo1 {
    max-width: 100%;
}

.header-title1 {
    margin: 24px 0 16px;
    font-size: 22px;
    letter-spacing: 0;
    text-align: center;
    font-weight: 500;
}

.text-simple1 {
    text-align: left;
    font-size: 17px;
    line-height: 1.3;
    color: inherit;
}

.margin-0 {
    margin: 0;
}

.button-bar1 {
    padding: 0;
    margin: 0 0 20px;
    text-align: center;
}

.loading-container {
	pointer-events: 'none'; 
	opacity: 0.2 
}

.loading-bounce-container {
    position: absolute;
    z-index: 9; 
    width: 100%; 
    height: 100%;
}
