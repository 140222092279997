
div.customerFleetList {
	margin:0;
	padding:0;
	min-width: 10vw;
}

div.customerFleetList tr.dx-header-row {
	display: none;
}

div.customerFleetList tr.dx-data-row:hover,
div.customerFleetList tr.dx-data-row td:hover {
	background-color: #AAAAAA33 !important;
}

div.customerFleetListDialog div.MuiDialogContent-root {
	padding:0;
}
